

/* ===================================================================
// ============================ Input Fields =========================
// =================================================================== */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* ===================================================================
// ========================= Navigation Ribbon =======================
// =================================================================== */

.ribbon {
  line-height: 0.8em;
  font-size: 1.5em;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
  letter-spacing: -2px;
  display: block;
  width: 3rem;
  height: 4rem;
  background: linear-gradient(to bottom, #999999 0%, #cccccc 100%);
  color: white;
  margin: 0 0.5em 0;
  float: left;
  padding-top: 1.2rem;
  position: relative;
  -webkit-filter: drop-shadow(0 0.5rem 0.3em rgba(0, 0, 0, 0.5));
  transform: translate3d(0, 0, 0);
}

.ribbon::after {
  content: "";
  width: 0;
  height: 0;
  border-right: 1.5rem solid transparent;
  border-left: 1.5rem solid transparent;
  border-top: 1.5rem ;
  position: absolute;
  top: 4rem;
  left: 0;
}

.ribbon--red {
  background: #d32f2f;
}
.ribbon--red::after {
  border-top: 1.5rem solid #d32f2f;
}
.ribbon--orange {
  background: linear-gradient(to bottom, #e7711b 0%, #f7981d 100%);
}
.ribbon--orange::after {
  border-top: 1.5rem solid #f7981d;
}

.ribbon--yellow {
  background: linear-gradient(to bottom, #f1ca3a 0%, #f6eb3b 100%);
}
.ribbon--yellow::after {
  border-top: 1.5rem solid #f6eb3b;
}
.ribbon--green {
  background: linear-gradient(to bottom, #5f9654 0%, #65b045 100%);
}

.ribbon--green::after {
  border-top: 1.5rem solid #65b045;
}

.ribbon--blue {
  background: linear-gradient(to bottom, #1c91c0 0%, #11a9cc 100%);
}
.ribbon--blue::after {
  border-top: 1.5rem solid #11a9cc;
}

.ribbon--purple {
  background: linear-gradient(to bottom, #5c3292 0%, #7e3794 100%);
}
.ribbon--purple:after {
  border-top: 1.5rem solid #7e3794;
}



/* ===================================================================
// =========================== Corner Ribbon =========================
// =================================================================== */
.cornerRibon {
  width: 250px;
  padding: 0.5rem;
  overflow: hidden;
  text-align: center;
  color: #fff;
  font-size: large;
}

.cornerRibon-sticky {
  position: absolute;
}

/* Positions */
.cornerRibon-top {
  top: 5.5rem;
}
.cornerRibon-bottom {
  bottom: 2.5rem;
}
.cornerRibon-left {
  left: 2.5rem;
}
.cornerRibon-right {
  right: -5rem;
}

/* Rotations */
.cornerRibon-top.cornerRibon-left,
.cornerRibon-bottom.cornerRibon-right {
  transform: rotate(-45deg);
}

.cornerRibon-top.cornerRibon-right,
.cornerRibon-bottom.cornerRibon-left {
  transform: rotate(45deg);
}

/* Colors */
.cornerRibon-white {
  background-color: #f0f0f0;
  color: #444;
}
.cornerRibon-black {
  background-color: #333;
}
.cornerRibon-grey {
  background-color: #999;
}
.cornerRibon-blue {
  background-color: #39d;
}
.cornerRibon-green {
  background-color: #2c7;
}
.cornerRibon-turquoise {
  background-color: #1b9;
}
.cornerRibon-purple {
  background-color: #95b;
}
.cornerRibon-red {
  background-color: #d32f2f;
}
.cornerRibon-orange {
  background-color: #e82;
}
.cornerRibon-yellow {
  background-color: #ec0;
}

/* ===================================================================
// ============================ Card Ribbon ==========================
// =================================================================== */
.cardRibbon {
  line-height: 1em;
  font-size: 1.5em;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
  letter-spacing: -2px;
  display: block;
  width: 2rem;
  height: 2rem;
  background: linear-gradient(to bottom, #999999 0%, #cccccc 100%);
  color: white;
  /* margin-right: 2em; */
  float: right;
  padding-top: 0.5rem;
  position: absolute;
  right: 1.5rem;
  -webkit-filter: drop-shadow(0 0.5rem 0.3em rgba(0, 0, 0, 0.5));
  transform: translate3d(0, 0, 0);
  z-index: 1;
}
.cardRibbon::after {
  content: "";
  width: 0;
  height: 0;
  border-right: 1rem solid transparent;
  border-left: 1rem solid transparent;
  /* border-top: 0.5rem solid #cccccc; */
  position: absolute;
  top: 2rem;
  left: 0;
}

.cardRibbon--red {
  background: #d32f2f;
}
.cardRibbon--red::after {
  border-top: 1rem solid #d32f2f;
}
.cardRibbon--orange {
  background: linear-gradient(to bottom, #e7711b 0%, #f7981d 100%);
}
.cardRibbon--orange::after {
  border-top: 1.5rem solid #f7981d;
}

.cardRibbon--yellow {
  background: linear-gradient(to bottom, #f1ca3a 0%, #f6eb3b 100%);
}
.cardRibbon--yellow::after {
  border-top: 1.5rem solid #f6eb3b;
}
.cardRibbon--green {
  background: linear-gradient(to bottom, #5f9654 0%, #65b045 100%);
}

.cardRibbon--green::after {
  border-top: 1.5rem solid #65b045;
}

.cardRibbon--blue {
  background: linear-gradient(to bottom, #1c91c0 0%, #11a9cc 100%);
}
.cardRibbon--blue::after {
  border-top: 1.5rem solid #11a9cc;
}

.cardRibbon--purple {
  background: linear-gradient(to bottom, #5c3292 0%, #7e3794 100%);
}

.cardRibbon--purple:after {
  border-top: 1.5rem solid #7e3794;
}
/* ===================================================================
// ======================== Corner Tape Ribbon =======================
// =================================================================== */
.cornerTapeBox {
  position: relative;
}

/* common */
.cornerTape {
  width: 8rem;
  height: 8rem;
  overflow: hidden;
  position: absolute;
  z-index: 1;
}
.cornerTape::before,
.cornerTape::after {
  position: absolute;
  /* z-index: 999; */
  content: "";
  display: block;
  border: 5px solid #a32525;
}
.cornerTape span {
  position: absolute;
  display: block;
  width: 13rem;
  padding: 0.75rem 0;
  background-color: #d32f2f;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 "Lato", sans-serif;
  /* text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2); */
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.cornerTape-top-left {
  top: -10px;
  left: -10px;
}
.cornerTape-top-left::before,
.cornerTape-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.cornerTape-top-left::before {
  top: 0;
  right: 0;
}
.cornerTape-top-left::after {
  bottom: 0;
  left: 0;
}
.cornerTape-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

/* top right*/
.cornerTape-top-right {
  top: -10px;
  right: -10px;
}
.cornerTape-top-right::before,
.cornerTape-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.cornerTape-top-right::before {
  top: 0;
  left: 0;
}
.cornerTape-top-right::after {
  bottom: 0;
  right: 0;
}
.cornerTape-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

/* bottom left*/
.cornerTape-bottom-left {
  bottom: -10px;
  left: -10px;
}
.cornerTape-bottom-left::before,
.cornerTape-bottom-left::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.cornerTape-bottom-left::before {
  bottom: 0;
  right: 0;
}
.cornerTape-bottom-left::after {
  top: 0;
  left: 0;
}
.cornerTape-bottom-left span {
  right: -25px;
  bottom: 30px;
  transform: rotate(225deg);
}

/* bottom right*/
.cornerTape-bottom-right {
  bottom: -10px;
  right: -10px;
}
.cornerTape-bottom-right::before,
.cornerTape-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.cornerTape-bottom-right::before {
  bottom: 0;
  left: 0;
}
.cornerTape-bottom-right::after {
  top: 0;
  right: 0;
}
.cornerTape-bottom-right span {
  left: -25px;
  bottom: 30px;
  transform: rotate(-225deg);
}
/* ===================================================================
// =========================== Quill Editor ==========================
// =================================================================== */
.quill > * {
  border-color: inherit !important;
  color: inherit !important;
}
.quill > .ql-toolbar {
  /* border radius of the toolbar */
  border-radius: 4px 4px 0 0;
}
.quill > .ql-container {
  /* border radius of the container and for font size*/
  font-size: inherit;
  border-radius: 0 0 4px 4px;
}
.ql-toolbar.ql-snow .ql-picker-label {
  color: inherit !important;
  opacity: 0.76;
}
.ql-snow .ql-picker {
  color: inherit !important;
}
.quill > .ql-container > .ql-editor.ql-blank::before {
  /* for placeholder */
  color: inherit;
}
.ql-snow.ql-toolbar button svg {
  opacity: 0.76;
  color: currentColor;
}
.ql-snow .ql-stroke {
  /* for the border of the editor */
  stroke: currentColor !important;
}
.ql-snow .ql-fill {
  /* for the bg color */
  fill: currentColor !important;
}
.ql-picker-item {
  /* for dropdown */
  color: #444 !important;
}
.ql-active {
  color: #006064 !important;
}
/* Stile für den Dark Mode */
@media (prefers-color-scheme: dark) {
  .ql-active {
    color: #00bcd4 !important;
  }
}


/* Test umgebung */
/* .ql-active {
  color: #AB47BC !important;
}

@media (prefers-color-scheme: dark) {
  .ql-active {
    color: #6a1b9a !important;
  }
} */
